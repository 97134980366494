<template>
  <div class="newUser">
    <p class="titleClass">新用户打印引导</p>
    <div class="contentDiv">
         <div :class="setType[0] ?'contentItem active' :'contentItem'">
        <div>
          <i class="el-icon-warning" v-if="!setType[0]"></i>
          <i class="el-icon-success" v-if="setType[0]"></i>
          <p>发件人信息：</p>
          <p class="textBtn" v-if="!setType[0]" @click="setInfoBtn">立即设置</p>
          <p v-if="setType[0]">已设置</p>
        </div>
        <div>
          <p class="spotClass">.</p>
          <p v-if="!setType[0]">打单发货前需先设置发件人信息</p>
          <p v-if="setType[0]" style="padding-right: 15px">
            {{
              this.express_list[0].name
            }}
            {{
              this.express_list[0].phone
            }}
            
          </p>
        </div>
        <div>
          <span><span style="margin:0;color:#000">基础设置</span>：可配置更多</span>
          <i class="el-icon-edit" style="cursor: pointer;" @click="setInfoBtn"></i>
        </div>
      </div>
      <div :class="setType[1] ?'contentItem active' :'contentItem'">
        <div>
          <i class="el-icon-warning" v-if="!setType[1]"></i>
          <i class="el-icon-success" v-if="setType[1]"></i>
          <p>快递单设置：</p>
          <p class="textBtn" v-if="!setType[1]" @click="setInfoBtn">立即设置</p>
          <p v-if="setType[1]">已设置</p>
        </div>
        <div style="margin-left: 17px">
          <p style="padding-right: 15px">快手电子面单已开通
            {{expressText}}
          </p>
        </div>
        <!-- <div>
          <span>绑定关联店铺 可用其他店铺面单账号</span>
          <i class="el-icon-edit"></i>
        </div> -->
      </div>
      <div :class="setType[2] ?'contentItem active' :'contentItem'">
        <div>
          <i class="el-icon-warning" v-if="!setType[2]"></i>
          <i class="el-icon-success" v-if="setType[2]"></i>
          <p>打印组件安装：</p>
          <p class="textBtn" v-if="!setType[2]" @click="dialogShow = true">立即安装</p>
          <p v-if="setType[2]">已安装</p>
        </div>
        <div>
          <p class="spotClass">.</p>
          <p v-if="!setType[2]">打印前需安装快手打印组件</p>
          <p v-if="setType[2]">打印组件已安装完成</p>
          
        </div>
      </div>
    </div>
    <div class="contentBtn">
      
      <div @click="refreshBtn" style="color: black;border: 1px solid #999;">
        如已设置，刷新试试
      </div>
    </div>
    <!-- 提示安装打印控件 -->
    <el-dialog
      :visible.sync="dialogShow"
      width="30%"
      background-color='#fff'
      :show-close = 'false'
      center>
      <div class="contentClass3">
        <div>
          <i class="el-icon-warning"></i>
          <span>您未安装打印控件</span>
          <p style="margin-top:15px;width:100%">安装完成请<span class="refreshBtn" @click="refreshBtn">刷新页面</span>或重启浏览器</p>
        </div>
        <div class="contentFooter">
          <div @click="comfirBtn">立即下载</div>
          <div @click="dialogShow = false">取消</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "newUser",
  data() {
    return {
      dialogShow: false,
      expressText: '',
    };
  },
  props: ['setType'],
  created() {
    for(let i in this.expressTemplateList) {
      this.expressText += i<this.expressTemplateList.length-1 ?`${this.expressTemplateList[i].express_name}、` :`${this.expressTemplateList[i].express_name}`
    }
  },
  computed: {
    ...mapState({
      info_Invoice: (state) => state.setting.invoice_list,
      express_list: (state) => state.setting.express_list,
      expressTemplateList: (state) => state.print.expressTemplateList,
    }),
  },
  methods: {
    setInfoBtn() {
      window.sessionStorage.setItem("activeId", 3);
      this.$router.push('/setting');
    },
    comfirBtn() {
      window.open('/打印控件.zip')
    },
    refreshBtn() {
      location.reload();
    },
    contactBtn() {
      window.open("https://amos.alicdn.com/getcid.aw?spm=a261y.7663282.autotrace-topbar.154.4e6e66b9A4r8FI&v=3&groupid=0&s=1&charset=utf-8&uid=%E5%85%B4%E4%B8%9A%E5%AE%9D%E4%BA%91%E4%BB%93&site=cnalichn")
    },
  },
};
</script>

<style lang="less" scoped>
  .newUser {
    width: 100%;
    min-height: 100px;
    background-color: #fafafa;
    padding-top: 1px;
    padding-bottom: 1px;
    margin-top: 130px;
    .titleClass {
      color: #303133;
      text-align: center;
      font-size: 16px;
      line-height: 16px;
      margin-top: 20px;
    }
    .contentDiv {
      display: flex;
      width: 900px;
      align-items: center;
      justify-content: space-between;
      margin: 20px auto 20px;
    }
    .contentItem {
      width: 270px;
      height: 110px;
      border: 1px solid #dcdee3;
      border-top: 3px solid #dcdee3;
      border-radius: 3px;
      background-color: #fff;
      padding-top: 25px;
      padding-left: 15px;
      div:nth-child(1) {
        display: flex;
        font-size: 14px;
        line-height: 16px;
        align-items: center;
      }
      div:nth-child(2) {
        display: flex;
        font-size: 14px;
        line-height: 16px;
        align-items: center;
        color: #8790a3;
        margin-top: 15px;
        .spotClass {
          font-size: 30px;
          line-height: 30px;
          margin: 0;
          margin-top: -20px;
          margin-right: 10px;
        }
      }
      div:nth-child(3) {
        margin-top: 15px;
        color:#8790a3;
        font-size: 14px;
        line-height: 14px;
        span {
          margin-right: 15px
        }
      }
    }
    .active {
      border-top: 3px solid #f5821f;
    }
    .el-icon-warning {
      color: #f23c3c;
      font-size: 16px;
      line-height: 16px;
      margin-right: 10px;
    }
    .el-icon-success {
      color: #67c23a;
      font-size: 16px;
      line-height: 16px;
      margin-right: 10px;
    }
    .textBtn {
      color: #f5821f;
      cursor: pointer;
    }
    .contentBtn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      img {
        width: 16px;
        margin-left: 10px;
      }
      div {
        font-size: 14px;
        line-height: 14px;
        cursor: pointer;
        padding: 8px 11px;
        border: 1px solid #cccccc;
        border-radius: 3px;
      }
      div:nth-child(1) {
        margin-right: 15px;
        border: 1px solid #f5821f;
        color: #f5821f;
        display: flex;
        align-items: center;
      }
    }

    
    /deep/ .el-dialog {
      background-color: #fff;
    }
    /deep/ .el-dialog__header {
        padding: 0;
    }
    /deep/ .el-dialog__body {
        padding: 0;
    }
    /deep/ .el-dialog__close{
        font-size: 20px;
        font-weight: 600;
        color: #2c2b3b;
    }
    /deep/ .el-dialog__close:hover {
        color: #2c2b3b;
    }
    .contentClass3 {
      color: #000;
      padding: 15px;
      .refreshBtn {
        font-size:14px;
        line-height:14px;
        color:#3366cc;
        margin:0;
        text-decoration:underline;
        cursor: pointer;
      }
      i {
        font-size: 26px;
        line-height: 26px;
        color: #ff7614;
      }
      span {
        font-size: 18px;
        line-height: 18px;
        font-weight: 600;
        color: #ff7614;
        margin-left: 10px;
      }
      div:nth-child(1) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        p {
          margin-left: 36px;
        }
      }
      .contentFooter {
        width: 100%;
        text-align: right;
        margin-top: 40px;
        div {
          background-color: #ff7614;
          padding: 5px 18px;
          display: inline-block;
          color: #fff;
          border-radius: 3px;
          cursor: pointer;
        }
        div:nth-child(2) {
          background-color: #fff;
          border: 1px  solid #b4b4b4;
          color: #333;
          margin-left: 10px;
        }
      }
    }
  }
</style>